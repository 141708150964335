@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap");

@font-face {
  font-family: Roboto-Regular;
  src: url(./fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(./fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: BebasNeue-Regular;
  src: url(./fonts/Bebas_Neue/BebasNeue-Regular.ttf);
}

@font-face {
  font-family: Preahvihear;
  src: url(./fonts/Preahvihear/Preahvihear-Regular.ttf);
}

@font-face {
  font-family: pretendard;
  src: url(./fonts/pretendard/variable/PretendardVariable.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.trirong-thin {
  font-family: "Trirong", serif;
  font-weight: 100;
  font-style: normal;
}

.trirong-extralight {
  font-family: "Trirong", serif;
  font-weight: 200;
  font-style: normal;
}

.trirong-light {
  font-family: "Trirong", serif;
  font-weight: 300;
  font-style: normal;
}

.trirong-regular {
  font-family: "Trirong", serif;
  font-weight: 400;
  font-style: normal;
}

.trirong-medium {
  font-family: "Trirong", serif;
  font-weight: 500;
  font-style: normal;
}

.trirong-semibold {
  font-family: "Trirong", serif;
  font-weight: 600;
  font-style: normal;
}

.trirong-bold {
  font-family: "Trirong", serif;
  font-weight: 700;
  font-style: normal;
}

.trirong-extrabold {
  font-family: "Trirong", serif;
  font-weight: 800;
  font-style: normal;
}

.trirong-black {
  font-family: "Trirong", serif;
  font-weight: 900;
  font-style: normal;
}

.trirong-thin-italic {
  font-family: "Trirong", serif;
  font-weight: 100;
  font-style: italic;
}

.trirong-extralight-italic {
  font-family: "Trirong", serif;
  font-weight: 200;
  font-style: italic;
}

.trirong-light-italic {
  font-family: "Trirong", serif;
  font-weight: 300;
  font-style: italic;
}

.trirong-regular-italic {
  font-family: "Trirong", serif;
  font-weight: 400;
  font-style: italic;
}

.trirong-medium-italic {
  font-family: "Trirong", serif;
  font-weight: 500;
  font-style: italic;
}

.trirong-semibold-italic {
  font-family: "Trirong", serif;
  font-weight: 600;
  font-style: italic;
}

.trirong-bold-italic {
  font-family: "Trirong", serif;
  font-weight: 700;
  font-style: italic;
}

.trirong-extrabold-italic {
  font-family: "Trirong", serif;
  font-weight: 800;
  font-style: italic;
}

.trirong-black-italic {
  font-family: "Trirong", serif;
  font-weight: 900;
  font-style: italic;
}

.preahvihear-regular {
  font-family: "Preahvihear", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.pretendard {
  font-family: "pretendard", sans-serif;
  letter-spacing: -0.05em;
}
