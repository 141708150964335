:root {
  --swiper-navigation-color: #000;
  --swiper-theme-color: #000;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}
