@import "../index";

.program-review-card-swiper {
  width: 300px;
  @media screen and (max-width: $sm) {
    width: 250px;
  }
}

.program-review-poster-info {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $sm) {
    text-align: center;
    flex-direction: column;
    justify-content: unset;
  }
}
