.home-swiper {
  &.swiper-button-next {
    &::after {
      content: none;
    }
  }

  &.swiper-button-prev {
    &::after {
      content: none;
    }
  }
  &.swiper-button-disabled {
    pointer-events: auto;
  }
}

.home-banner-swiper {
  position: relative;
}
