@import "../index";

.question-container {
  padding: 10px;
  cursor: pointer;
  background: #fff;
  border: 1px solid rgb(175, 170, 170);
  border-radius: 5px;
}

.question {
  border-left: 5px solid $text-color;
  padding-left: 10px;
}

.toggle {
  color: red;
  border-left: 5px solid red;
}

.m-center {
  @media screen and (max-width: $sm) {
    display: flex;
    justify-content: center;
  }
}
