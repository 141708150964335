@import "../index.scss";

.stepper-container {
  width: 600px;
  margin: 0px auto;
  @media screen and (max-width: $sm) {
    width: 100%;
  }
}

.stepper-item-container {
  width: 100%;
  padding: 5%;
}

.cart-image {
  width: 250px;
  height: 250px;

  @media screen and (max-width: $sm) {
    width: 100px;
    height: 100px;
  }
}

.program-card-container {
  height: 300px;

  @media screen and (max-width: $sm) {
    height: 100px;
  }
}
