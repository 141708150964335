@import "../font.scss";

.info-init-brand {
  color: #999999;
  &:hover {
    color: #111111;
    * {
      fill: #111111;
    }
  }
}

.home-program-review-card {
  path {
    stroke: #767676;
  }
}

.home-button {
  background: #4a148c;
  color: white;
  cursor: pointer;
  user-select: none;
  &.outlined {
    background: inherit;
    color: #4a148c;
    border: 1px solid #4a148c;
  }

  &.no-color {
    background: inherit;
    border: 1px solid #cccccc;
    color: #cccccc;
  }

  &:hover {
    background: rgba(74, 20, 140, 0.2);
    border: 1px solid inherit;
    box-sizing: border-box;
    color: #4a148c;
    transition-duration: 0.1s;
  }

  &.disabled {
    cursor: inherit;
    &.no-color {
      background: #cccccc;
      color: white;
      border: none;
    }
  }

  &.selected {
    background: rgba(74, 20, 140, 0.2);
    border: 1px solid #4a148c;
    box-sizing: border-box;
    color: #4a148c;
  }
}

.home-hover-font-zoom {
  &:hover {
    font-weight: 700;
  }
}

.home-hover-zoom {
  width: 100%;
  height: 100%;
  transition: 0.5s all ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

.font-noto-black {
  font-family: "Noto Sans KR";
  font-weight: 900;
}

.font-noto-regular {
  font-family: "Noto Sans KR";
  font-weight: 400;
}

.font-noto-light {
  font-family: "Noto Sans KR";
  font-weight: 300;
}

.font-noto-medium {
  font-family: "Noto Sans KR";
  font-weight: 500;
}

.font-roboto-medium {
  font-family: Roboto-Medium;
}

.font-roboto-regular {
  font-family: Roboto-Regular;
}

.font-bebas-regular {
  font-family: BebasNeue-Regular;
}
