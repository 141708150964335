@import "../../index";

.program-review-card-container {
  background: #b5b5b5;
  &.review {
    background: #5d5d5d;
  }
  width: 250px;
}

.program-review-detail {
  height: 400px;
  @media screen and (max-width: $sm) {
    height: 200px;
  }
}
