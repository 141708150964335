.cart {
  svg {
    width: 40px;
    height: 40px;
  }
}

.navbar-container {
  padding: 15px 30px;
}
