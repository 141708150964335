$text-color: #292929;
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

@mixin type-side-number-class($type, $side, $number) {
  $firstTypeLetter: str-slice($type, 0, 1);
  $firstSideLetter: str-slice($side, 0, 1);
  .#{$firstTypeLetter}#{$firstSideLetter}-#{$number} {
    #{$type}-#{$side}: #{$number}px !important;
    @media screen and (max-width: $sm) {
      #{$type}-#{$side}: #{$number / 2}px !important;
    }
  }
}

@mixin type-number-class($type, $number) {
  $firstTypeLetter: str-slice($type, 0, 1);
  .#{$firstTypeLetter}-#{$number} {
    #{$type}: #{$number}px !important;
    @media screen and (max-width: $sm) {
      #{$type}: #{$number / 2}px !important;
    }
  }
}

@mixin first-property-type-class($property, $type) {
  $firstProperty: str-slice($property, 0, 1);
  .#{$firstProperty}-#{$type} {
    #{$property}: #{$type} !important;
  }
}

@mixin property-type-class($property, $type) {
  .#{$property}-#{$type} {
    #{$property}: #{$type};
  }
}

@mixin generate-first-property-types($property, $types) {
  @each $type in $types {
    @include first-property-type-class($property, $type);
  }
}

@mixin generate-property-types($property, $types) {
  @each $type in $types {
    @include property-type-class($property, $type);
  }
}

$types: ("margin", "padding");
$sides: ("top", "right", "bottom", "left");
$number: 0;

@while $number < 50 {
  $number: $number + 5;
  @each $type in $types {
    @include type-number-class($type, $number);
    @each $side in $sides {
      @include type-side-number-class($type, $side, $number);
    }
  }
}

$display-type: ("flex", "none", "block", "inline-block");
@include generate-first-property-types("display", $display-type);

$display-type: (
  "center",
  "space-between",
  "flex-end",
  "flex-start",
  "space-evenly"
);
@include generate-property-types("justify-content", $display-type);

$display-type: ("start", "center", "end");
@include generate-property-types("text-align", $display-type);

$display-type: ("center", "flex-end");
@include generate-property-types("align-items", $display-type);

$display-type: ("absolute", "relative", "static", "sticky");
@include generate-first-property-types("position", $display-type);

$display-type: ("none");
@include generate-property-types("text-decoration", $display-type);

$display-type: ("scroll", "auto", "hidden");
@include generate-property-types("overflow", $display-type);

@mixin apply-unit($class, $prop, $number, $mobile-times, $unit) {
  .#{$class} {
    #{$prop}: #{$number}#{$unit} !important;
    @media screen and (max-width: $sm) {
      #{$prop}: #{$number * $mobile-times}#{$unit} !important;
    }
  }
}

@include apply-unit("min-width-0", "min-width", 0, 1, "px");

.width-100-percentage {
  width: 100%;
}

.width-50-percentage {
  width: 50%;
}

.width-75-percentage {
  width: 75%;
}

.height-100-percentage {
  height: 100%;
}

@for $number from 0 through 30 {
  @include apply-unit("font-#{$number}", "font-size", $number, 0.75, "px");
}

$count: 0;

@while $count < 1000 {
  $count: $count + 5;
  @include apply-unit("width-#{$count}", "width", $count, 0.75, "px");
  @include apply-unit("height-#{$count}", "height", $count, 0.75, "px");
}

.background-blue {
  background-color: #2699fb !important;
}

.text-blue {
  color: #2699fb !important;
}

.text-white {
  color: #fff !important;
}

.font-bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal;
}

.font-header {
  font-size: 25px;
  font-weight: 900;

  @media screen and (max-width: $sm) {
    font-size: 16px;
  }
}

.font-sub {
  font-size: 18px;

  @media screen and (max-width: $sm) {
    font-size: 14px;
  }
}

.font-sub2 {
  font-size: 15px;

  @media screen and (max-width: $sm) {
    font-size: 10px;
  }
}

.z-back {
  z-index: -1;
}

.font-small {
  font-size: 10px;
}

.font-inherit {
  font-size: inherit;
}

.color-inherit {
  color: inherit;
}

.text-red,
.error {
  color: rgb(255, 0, 0);
}

.app {
  display: flex;
  justify-content: center;
  font-family: Noto Sans KR;

  .container {
    width: 100%;

    .content-container {
      // max-width: 1800px;
      margin: 0 auto;
    }
  }
}

.cursor-pointer {
  cursor: pointer;

  .hover-underline {
    &:hover {
      text-decoration: underline;
    }
  }
}

.whitespace-preline {
  white-space: pre-line;
}

.user-select-none {
  user-select: none;
}

.flex-column {
  flex-direction: column;
}

.flex-one {
  flex: 1 0 0;
}

.image-opacity {
  opacity: 0.4;
}

.no-inset {
  inset: 0px;
}

.text-secondary {
  color: #7f7f7f;
}

.p-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background-dark {
  background-color: $text-color !important;
}

.background-black {
  background-color: black;
}

.m-block {
  @media screen and (max-width: $sm) {
    display: block;
  }
}

.margin-center {
  margin: 0 auto;
}

.red-button {
  font-size: 10px;
  padding: 5px;
  color: white;
  background-color: pink;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.background-gray {
  background-color: #e6e6e6 !important;
}

.outline-with-border {
  border: 1px solid lightgray;
  border-radius: 8px;
}

.background-white {
  background-color: white;
}

.background-red {
  background-color: #ff5a6e !important;
}

.text-gray {
  color: #5d5d5d;
}

.text-dark {
  color: $text-color;
}

.justify-self-stretch {
  justify-self: stretch;
}
